import QueryString from "./src/helper/QueryString";
// require("./src/styles/index.scss");

export const onRouteUpdate = () => {
    let query = new QueryString();

    if (typeof window !== "undefined") {
        query.parseQuery(window.location.search);
        if (!query.getQuery().originalLandingPage) {
            query.setValue("originalLandingPage", window.location.href);
        }
    }

    if (
        typeof document !== "undefined" &&
        typeof document.referrer !== "undefined" &&
        document.referrer !== ""
    ) {
        query.setValue("referrer", document.referrer);
    }
};

// Remove the style after confirming no redirection
// export const onInitialClientRender = () => {
//     const hideStyle = document.getElementById("initial-hide");
//     if (hideStyle) {
//         hideStyle.remove();
//     }
//     document.documentElement.style.visibility = "visible";
//     document.body.style.visibility = "visible";
// };

// export const onClientEntry = () => {
//     console.log("Script executed before React renders");

//     if (typeof window !== "undefined") {
//         // Dynamically add CSS to hide the content initially
//         const style = document.createElement("style");
//         style.id = "initial-hide"; // Assign an ID for easier removal later
//         style.innerHTML = `
//           html, body {
//               visibility: hidden;
//           }
//         `;
//         document.head.appendChild(style);

//         const redirectToNewDomain = () => {
//             // Redirect to the new domain
//             const currentPath = window.location.pathname; // Get the current path
//             const queryParams = window.location.search; // Get the current query string
//             window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//         };

//         const checkRedirectionCondition = async () => {
//             try {
//                 const response = await fetch(
//                     `${process.env.GATSBY_API_URL}/userTrack`
//                 );
//                 const data = await response.json();

//                 if (data.usertype && data.usertype % 4 === 0) {
//                     console.log(
//                         "Usertype meets the redirection condition:",
//                         data.usertype
//                     );
//                     redirectToNewDomain();
//                 } else {
//                     console.log(
//                         "Usertype does not meet the redirection condition:",
//                         data.usertype
//                     );

//                     // Remove the dynamically added CSS rule
//                     const hideStyle = document.getElementById("initial-hide");
//                     if (hideStyle) {
//                         hideStyle.remove();
//                     }

//                     // Ensure visibility is restored explicitly
//                     document.documentElement.style.visibility = "visible";
//                     document.body.style.visibility = "visible";
//                 }
//             } catch (error) {
//                 console.error("Error fetching userTrack data:", error);

//                 // Fallback: Remove the CSS rule in case of an error
//                 const hideStyle = document.getElementById("initial-hide");
//                 if (hideStyle) {
//                     hideStyle.remove();
//                 }

//                 document.documentElement.style.visibility = "visible";
//                 document.body.style.visibility = "visible";
//             }
//         };

//         // Call the redirection check
//         checkRedirectionCondition();
//     }

//     console.log("Finished: Script executed before React renders");
// };

// export const onClientEntry = () => {
//     console.log("Script executed before React renders");

//     // if (typeof window !== "undefined") {
//     // Dynamically add CSS to hide the content initially
//     const style = document.createElement("style");
//     style.id = "initial-hide"; // Assign an ID for easier removal later
//     style.innerHTML = `
//       html, body {
//           visibility: hidden;
//       }
//     `;
//     document.head.appendChild(style);

//     const redirectToNewDomain = () => {
//         // Redirect to the new domain
//         const currentPath = window.location.pathname; // Get the current path
//         const queryParams = window.location.search; // Get the current query string
//         window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//     };

//     const checkRedirectionCondition = async () => {
//         try {
//             const cacheKey = "userTrackResponse";
//             const cacheExpiryKey = "userTrackExpiry";
//             const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//             const now = Date.now();

//             // Retrieve cached data
//             const cachedResponse = localStorage.getItem(cacheKey);
//             const cachedExpiry = localStorage.getItem(cacheExpiryKey);

//             let usertype;

//             if (
//                 cachedResponse &&
//                 cachedExpiry &&
//                 now < parseInt(cachedExpiry, 10)
//             ) {
//                 console.log("Using cached response");
//                 usertype = JSON.parse(cachedResponse).usertype;
//             } else {
//                 console.log("Fetching new data from API");
//                 const response = await fetch(
//                     `${process.env.GATSBY_API_URL}/userTrack`
//                 );
//                 const data = await response.json();

//                 // Store response and expiry time in localStorage
//                 localStorage.setItem(cacheKey, JSON.stringify(data));
//                 localStorage.setItem(
//                     cacheExpiryKey,
//                     (now + cacheExpiryTime).toString()
//                 );

//                 usertype = data.usertype;
//             }

//             if (usertype && usertype % 4 === 0) {
//                 console.log(
//                     "Usertype meets the redirection condition:",
//                     usertype
//                 );
//                 redirectToNewDomain();
//             } else {
//                 console.log(
//                     "Usertype does not meet the redirection condition:",
//                     usertype
//                 );

//                 // Remove the dynamically added CSS rule
//                 const hideStyle = document.getElementById("initial-hide");
//                 if (hideStyle) {
//                     hideStyle.remove();
//                 }

//                 // Ensure visibility is restored explicitly
//                 document.documentElement.style.visibility = "visible";
//                 document.body.style.visibility = "visible";
//             }
//         } catch (error) {
//             console.error("Error fetching userTrack data:", error);

//             // Fallback: Remove the CSS rule in case of an error
//             const hideStyle = document.getElementById("initial-hide");
//             if (hideStyle) {
//                 hideStyle.remove();
//             }

//             document.documentElement.style.visibility = "visible";
//             document.body.style.visibility = "visible";
//         }
//     };

//     // Call the redirection check
//     checkRedirectionCondition();
//     // }

//     console.log("Finished: Script executed before React renders");
// };

// export const onClientEntry = () => {
//     console.log("Script executed before React renders");

//     // Dynamically add CSS to hide the content initially
//     const style = document.createElement("style");
//     style.id = "initial-hide"; // Assign an ID for easier removal later
//     style.innerHTML = `
//       html, body {
//           visibility: hidden;
//       }
//     `;
//     document.head.appendChild(style);

//     try {
//         const cacheKey = "trafficSplitRandom";
//         const cacheExpiryKey = "trafficSplitExpiry";
//         const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//         const now = Date.now();

//         // Retrieve cached random value and expiry
//         const cachedRandom = localStorage.getItem(cacheKey);
//         const cachedExpiry = localStorage.getItem(cacheExpiryKey);

//         let randomValue;

//         if (cachedRandom && cachedExpiry && now < parseInt(cachedExpiry, 10)) {
//             console.log("Using cached random value");
//             randomValue = parseFloat(cachedRandom);
//         } else {
//             console.log("Generating new random value");
//             randomValue = Math.random();

//             // Store random value and expiry in localStorage
//             localStorage.setItem(cacheKey, randomValue.toString());
//             localStorage.setItem(
//                 cacheExpiryKey,
//                 (now + cacheExpiryTime).toString()
//             );
//         }

//         if (randomValue < 0.25) {
//             // 25% chance for redirection
//             console.log(
//                 "Random value meets redirection condition:",
//                 randomValue
//             );
//            const currentPath = window.location.pathname; // Get the current path
//            const queryParams = window.location.search; // Get the current query string
//            window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//         } else {
//             console.log(
//                 "Random value does not meet redirection condition:",
//                 randomValue
//             );

//             // Remove the dynamically added CSS rule
//             const hideStyle = document.getElementById("initial-hide");
//             if (hideStyle) {
//                 hideStyle.remove();
//             }

//             // Ensure visibility is restored explicitly
//             document.documentElement.style.visibility = "visible";
//             document.body.style.visibility = "visible";
//         }
//     } catch (error) {
//         console.error("Error in redirection logic:", error);

//         // Fallback: Remove the CSS rule in case of an error
//         const hideStyle = document.getElementById("initial-hide");
//         if (hideStyle) {
//             hideStyle.remove();
//         }

//         document.documentElement.style.visibility = "visible";
//         document.body.style.visibility = "visible";
//     }

//     // Call the redirection check
//     checkRedirectionCondition();

//     console.log("Finished: Script executed before React renders");
// };
// export const onClientEntry = async () => {
//     console.log("Script executed before React renders");

//     // Dynamically add CSS to hide the content initially
//     const style = document.createElement("style");
//     style.id = "initial-hide"; // Assign an ID for easier removal later
//     style.innerHTML = `
//       html, body {
//           visibility: hidden;
//       }
//     `;
//     document.head.appendChild(style);

//     const ensureRedirectionLogic = async () => {
//         try {
//             const cacheKey = "trafficSplitRandom";
//             const cacheExpiryKey = "trafficSplitExpiry";
//             const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//             const now = Date.now();

//             // Retrieve cached random value and expiry
//             const cachedRandom = localStorage.getItem(cacheKey);
//             const cachedExpiry = localStorage.getItem(cacheExpiryKey);

//             let randomValue;

//             if (
//                 cachedRandom &&
//                 cachedExpiry &&
//                 now < parseInt(cachedExpiry, 10)
//             ) {
//                 console.log("Using cached random value");
//                 randomValue = parseFloat(cachedRandom);
//             } else {
//                 console.log("Generating new random value");
//                 randomValue = Math.random();

//                 // Store random value and expiry in localStorage
//                 localStorage.setItem(cacheKey, randomValue.toString());
//                 localStorage.setItem(
//                     cacheExpiryKey,
//                     (now + cacheExpiryTime).toString()
//                 );
//             }

//             if (randomValue < 0.25) {
//                 // 25% chance for redirection
//                 console.log(
//                     "Random value meets redirection condition:",
//                     randomValue
//                 );
//                 const currentPath = window.location.pathname; // Get the current path
//                 const queryParams = window.location.search; // Get the current query string
//                 window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//                 return;
//             } else {
//                 console.log(
//                     "Random value does not meet redirection condition:",
//                     randomValue
//                 );

//                 // Remove the dynamically added CSS rule
//                 const hideStyle = document.getElementById("initial-hide");
//                 if (hideStyle) {
//                     hideStyle.remove();
//                 }

//                 // Ensure visibility is restored explicitly
//                 document.documentElement.style.visibility = "visible";
//                 document.body.style.visibility = "visible";
//             }
//         } catch (error) {
//             console.error("Error in redirection logic:", error);

//             // Fallback: Remove the CSS rule in case of an error
//             const hideStyle = document.getElementById("initial-hide");
//             if (hideStyle) {
//                 hideStyle.remove();
//             }

//             document.documentElement.style.visibility = "visible";
//             document.body.style.visibility = "visible";
//         }
//     };

//     // Wait for the redirection logic to complete before proceeding
//     await ensureRedirectionLogic();

//     console.log("Finished: Script executed before React renders");
// };
// export const onClientEntry = async () => {
//     console.log("Script executed before React renders");

//     // Dynamically add CSS to hide the content initially
//     const style = document.createElement("style");
//     style.id = "initial-hide"; // Assign an ID for easier removal later
//     style.innerHTML = `
//       html, body {
//           visibility: hidden;
//       }
//     `;
//     document.head.appendChild(style);

//     const ensureRedirectionLogic = async () => {
//         try {
//             const cacheKey = "trafficSplitRandom";
//             const cacheExpiryKey = "trafficSplitExpiry";
//             const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//             const now = Date.now();

//             // Retrieve cached random value and expiry
//             const cachedRandom = localStorage.getItem(cacheKey);
//             const cachedExpiry = localStorage.getItem(cacheExpiryKey);

//             let randomValue;

//             if (
//                 cachedRandom &&
//                 cachedExpiry &&
//                 now < parseInt(cachedExpiry, 10)
//             ) {
//                 console.log("Using cached random value");
//                 randomValue = parseFloat(cachedRandom);
//             } else {
//                 console.log("Generating new random value");
//                 randomValue = Math.random();

//                 // Store random value and expiry in localStorage
//                 localStorage.setItem(cacheKey, randomValue.toString());
//                 localStorage.setItem(
//                     cacheExpiryKey,
//                     (now + cacheExpiryTime).toString()
//                 );
//             }

//             if (randomValue) {
//                 // 25% chance for redirection
//                 console.log(
//                     "Random value meets redirection condition:",
//                     randomValue
//                 );
//                 const currentPath = window.location.pathname; // Get the current path
//                 const queryParams = window.location.search; // Get the current query string
//                 window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//             } else {
//                 console.log(
//                     "Random value does not meet redirection condition:",
//                     randomValue
//                 );

//                 // Remove the dynamically added CSS rule
//                 const hideStyle = document.getElementById("initial-hide");
//                 if (hideStyle) {
//                     hideStyle.remove();
//                 }

//                 // Ensure visibility is restored explicitly
//                 document.documentElement.style.visibility = "visible";
//                 document.body.style.visibility = "visible";
//             }
//         } catch (error) {
//             console.error("Error in redirection logic:", error);

//             // Fallback: Remove the CSS rule in case of an error
//             const hideStyle = document.getElementById("initial-hide");
//             if (hideStyle) {
//                 hideStyle.remove();
//             }

//             document.documentElement.style.visibility = "visible";
//             document.body.style.visibility = "visible";
//         }
//     };

//     // Wait for the redirection logic to complete before proceeding
//     await ensureRedirectionLogic();

//     console.log("Finished: Script executed before React renders");
// };
// export const onClientEntry = () => {
//     console.log("Client-side entry script executed");

//     const cacheKey = "trafficSplitRandom";
//     const cacheExpiryKey = "trafficSplitExpiry";
//     const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//     const now = Date.now();

//     let cachedRandom = localStorage.getItem(cacheKey);
//     let cachedExpiry = localStorage.getItem(cacheExpiryKey);

//     if (!cachedRandom || !cachedExpiry || now >= parseInt(cachedExpiry, 10)) {
//         cachedRandom = Math.random();
//         localStorage.setItem(cacheKey, cachedRandom);
//         localStorage.setItem(cacheExpiryKey, now + cacheExpiryTime);
//     } else {
//         cachedRandom = parseFloat(cachedRandom);
//     }

//     // if (cachedRandom < 0.25) {
//     if (cachedRandom) {
//         // 25% redirection
//         const currentPath = window.location.pathname;
//         const queryParams = window.location.search;
//         window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//     }
// };

// export const onClientEntry = () => {
//     console.log("Script executed before React renders");

//     // if (typeof window !== "undefined") {
//     // Dynamically add CSS to hide the content initially
//     const style = document.createElement("style");
//     style.id = "initial-hide"; // Assign an ID for easier removal later
//     style.innerHTML = `
//       html, body {
//           display: none;
//       }
//     `;
//     document.head.appendChild(style);

//     const redirectToNewDomain = () => {
//         // Redirect to the new domain
//         const currentPath = window.location.pathname; // Get the current path
//         const queryParams = window.location.search; // Get the current query string
//         window.location.href = `https://ma.meinalarm24.de${currentPath}${queryParams}`;
//     };

//     const checkRedirectionCondition = async () => {
//         try {
//             const cacheKey = "userTrackResponse";
//             const cacheExpiryKey = "userTrackExpiry";
//             const cacheExpiryTime = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
//             const now = Date.now();

//             // Retrieve cached data
//             const cachedResponse = localStorage.getItem(cacheKey);
//             const cachedExpiry = localStorage.getItem(cacheExpiryKey);

//             let usertype = 4;

//             if (
//                 cachedResponse &&
//                 cachedExpiry &&
//                 now < parseInt(cachedExpiry, 10)
//             ) {
//                 console.log("Using cached response");
//                 usertype = JSON.parse(cachedResponse).usertype;
//             } else {
//                 console.log("Fetching new data from API");
//                 const response = await fetch(
//                     `${process.env.GATSBY_API_URL}/userTrack`
//                 );
//                 const data = await response.json();

//                 // Store response and expiry time in localStorage
//                 localStorage.setItem(cacheKey, JSON.stringify(data));
//                 localStorage.setItem(
//                     cacheExpiryKey,
//                     (now + cacheExpiryTime).toString()
//                 );

//                 usertype = data.usertype;
//             }
//             console.log("usertype isssssssssssss", usertype);

//             if (usertype && usertype % 4 === 0) {
//                 console.log(
//                     "Usertype meets the redirection condition:",
//                     usertype
//                 );
//                 redirectToNewDomain();
//             } else {
//                 console.log(
//                     "Usertype does not meet the redirection condition:",
//                     usertype
//                 );
//                 const hideStyle = document.getElementById("initial-hide");
//                 if (hideStyle) {
//                     hideStyle.remove();
//                 }
//             }
//         } catch (error) {
//             console.error("Error fetching userTrack data:", error);

//             // Fallback: Remove the CSS rule in case of an error
//             const hideStyle = document.getElementById("initial-hide");
//             if (hideStyle) {
//                 hideStyle.remove();
//             }

//             document.documentElement.style.visibility = "visible";
//             document.body.style.visibility = "visible";
//         }
//     };

//     // Call the redirection check
//     checkRedirectionCondition();
//     // }

//     console.log("Finished: Script executed before React renders");
// };
